import { h, Component, Fragment } from 'preact';
import styles from './icon.module.css';

interface IconProps {
  width?: number;
  height?: number;
  onClick?: any;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  style?: string;
  icon: any;
  title?: string;
  disableTooltip?: any;
}

interface IconState {
  width?: number;
  height?: number;
  onClick?: any;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  style?: string;
  icon: any;
  title?: any;
  disableTooltip?: any;
  tooltip: any;
}

export default class Icon extends Component<IconProps, IconState> {
  constructor(props:any) {
    super();

    this.state = {
      width: props.width || 32,
      height: props.height || 32,
      href: props.href,
      icon: props.icon,
      className: props.className || '',
      style: props.style,
      disableTooltip: !!props.disableTooltip || false,
      title: props.title || '',
      target: props.target || '_blank',
      rel: props.rel || '',
      onClick: props.onclick || null,
      tooltip: { active: false, content: null, style: null },
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  isLightOrDark(el:any) {
    let color = window.getComputedStyle(el).color;
    let rgb = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    ) as any[];
    let r: any = rgb[1];
    let g: any = rgb[2];
    let b: any = rgb[3];
    // HSP equation from http://alienryderflex.com/hsp.html
    return (
      Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > 127.5
    );
  }

  handleMouseOver(e:any) {
    if (this.state.title.length && !this.state.disableTooltip) {
      let bgColor = this.isLightOrDark(e.target)
        ? ''
        : 'background-color:rgba(255,255,255,0.7);';
      this.setState({
        tooltip: {
          active: true,
          style:
            bgColor +
            'display:block;left:' +
            parseInt(e.layerX + 10) +
            'px;top:' +
            parseInt(e.layerY + 10) +
            'px',
          content: this.state.title,
        },
      });
    }
  }

  handleMouseLeave(e:any) {
    if (this.state.title.length && !this.state.disableTooltip) {
      this.setState({
        tooltip: { active: false, style: '' },
      });
    }
  }

  render(props:any) {
    let icon = (
      <svg width={this.state.width} height={this.state.height}>
        <use xlinkHref={this.state.icon} />
      </svg>
    );

    let tooltip =
      this.state.title.length && !this.state.disableTooltip ? (
        <div class={styles.tooltip} style={this.state.tooltip.style}>
          {this.state.tooltip.content}
        </div>
      ) : null;

    let span = (
      <span
        aria-label={this.state.title}
        title={this.state.title}
        class={`${styles.link} ${this.state.className}`}
        style={this.state.style}
        onClick={props.onClick}
        onMouseMove={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        {icon}
        {tooltip}
      </span>
    );
    let link = (
      <a
        href={this.state.href ? this.state.href : undefined}
        rel={this.state.rel ? this.state.rel : undefined}
        target={this.state.target}
        aria-label={this.state.title}
        title={this.state.title}
        class={`${styles.link} ${this.state.className}`}
        style={this.state.style}
        onClick={props.onClick}
        onMouseMove={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        {icon}
        {tooltip}
      </a>
    );
    return <Fragment>{this.state.href ? link : span}</Fragment>;
  }
}
