import { h, Component, Fragment } from 'preact';
import Helmet from 'react-helmet';
import Icon from 'components/Icon';

import styles from './post.module.css';
import colors from 'style/colors.module.css';

interface PostProps {
  data: any;
  path: any;
}

interface PostState {
  title: string;
  description: string;
  content: string;
  date: string;
  color: string;
  background: string;
  parent: string;
  dateFormatted: Date;
  thumbnail: string;
  url: string;
  tags: string[];
  visible: Boolean;
}

export default class Posts extends Component<PostProps, PostState> {
  constructor(props: PostProps) {
    super();

    let content = props.data.content.rendered.replace(
      /http\:\/\/localhost\:4343\/wp-content/gi,
      ''
    );

    this.state = {
      title: props.data.title.rendered,
      description: props.data.acf.excerpt,
      content: content,
      color: props.data.acf.color,
      background: props.data.acf.background,
      date: props.data.date,
      dateFormatted: new Date(props.data.date),
      parent: props.path.split('/')[1],
      tags: props.data.categories,
      thumbnail: '/uploads/' + props.data.featured_image,
      url: `https://jlopes.eu/${props.path.split('/')[1]}/${props.data.slug}`,
      visible: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  onScroll = (e: Event) => {
    if (typeof document !== 'undefined') {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${(totalScroll / windowHeight) * 100}`;
      let elem = document.querySelector(
        '.' + styles.post + ' header hr'
      ) as HTMLElement;
      elem.style.width = scroll + '%';
    }
  };

  onClick(e: Event) {
    e.preventDefault();
    history.back();
  }

  componentDidMount() {
    this.setState({
      visible: true,
    });

    if (typeof document !== 'undefined') {
      document.body.setAttribute('class', colors['c_' + this.state.color]);
      document.addEventListener('scroll', this.onScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    this.setState({
      visible: false,
    });

    if (typeof document !== 'undefined') {
      document.removeEventListener('scroll', this.onScroll, false);
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet
          title={this.state.title}
          meta={[
            {
              name: 'description',
              content: `${this.state.description}`,
            },
            {
              property: 'fb:app_id',
              content: '327575387976968',
            },
            {
              property: 'og:image',
              content: `https://jlopes.eu${this.state.thumbnail}`,
            },
            {
              property: 'og:title',
              content: `${this.state.title} - J Lopes`,
            },
            {
              property: 'og:description',
              content: `${this.state.description}`,
            },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: `${this.state.url}` },
            {
              property: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              property: 'twitter:description',
              content: `${this.state.description}`,
            },
            {
              property: 'twitter:title',
              content: `${this.state.title} - J Lopes`,
            },
            { property: 'twitter:site', content: '@jelo' },
            {
              property: 'twitter:image',
              content: `https://jlopes.eu${this.state.thumbnail}`,
            },
          ]}
          link={[{ rel: 'canonical', href: `${this.state.url}` }]}
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
              "@context": "http://schema.org", 
              "@type": "Article",
              "headline": "${this.state.title}",
              "image": "https://jlopes.eu${this.state.thumbnail}",
              "author": "J Lopes",
              "genre": "Web Development", 
              "keywords": "webdev javascript css", 
              "url": "${this.state.url}",
              "dateCreated": "${this.state.date}",
              "datePublished": "${this.state.date}",
              "dateModified": "${this.state.date}",
              "description": "${this.state.description}",
              "articleBody": "${this.state.content.replace(/[\""]/g, '\\"')}",
              "publisher": {
                "@type": "Organization",
                "name": "J Lopes",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://jlopes.eu/assets/android-chrome-256x256.png"
                }
              },
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://jlopes.eu/"
              }
           }`,
            },
            {
              type: 'application/ld+json',
              innerHTML: `{
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://jlopes.eu"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Writings",
                "item": "https://jlopes.eu/writings"
              },{
                "@type": "ListItem",
                "position": 3,
                "name": "${this.state.title}",
                "item": "${this.state.url}"
              }]
            }`,
            },
          ]}
        />
        <section
          class={`
      ${styles.post} 
      ${colors['bg_' + this.state.background]} 
      ${colors['c_' + this.state.color]} 
      ${this.state.visible ? 'visible' : ''}`}
        >
          <header>
            <div
              class={styles.background}
              style={`background-image:url('${this.state.thumbnail}');`}
            />
            <h1>{this.state.title}</h1>
            <hr />
          </header>
          <article>
            <div class={styles['float']}>
              <Icon
                href={`/${this.props.path.split('/')[1]}`}
                icon={'#arrow-back'}
                width={48}
                height={48}
                title={`Back to ${this.props.path.split('/')[1]}`}
                target="_self"
              />
            </div>
            <section class={styles.meta}>
              <time
                dateTime={this.state.date}
              >{`${this.state.dateFormatted.getDate()} ${this.state.dateFormatted.toLocaleString(
                'en-uk',
                {
                  month: 'short',
                }
              )} ${this.state.dateFormatted.getFullYear()}`}</time>
              <div>
                <Icon
                  href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${this.state.url}`}
                  icon={'#facebook'}
                  width={25}
                  height={25}
                  title="facebook"
                />
                <Icon
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    this.state.description
                  )}&url=${this.state.url}`}
                  icon={'#twitter'}
                  width={25}
                  height={25}
                  title="twitter"
                />
                <Icon
                  href={`http://pinterest.com/pin/create/button?description=${encodeURIComponent(
                    this.state.description
                  )}&media=https://jlopes.eu${this.state.thumbnail}&url=${
                    this.state.url
                  }`}
                  icon={'#pinterest'}
                  width={25}
                  height={25}
                  title="pinterest"
                />
                <Icon
                  href={`https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(
                    this.state.title
                  )}&summary=${encodeURIComponent(
                    this.state.description
                  )}&url=${this.state.url}&source=LinkedIn`}
                  icon={'#linkedin'}
                  width={25}
                  height={25}
                  title="linkedin"
                />
              </div>
            </section>
            <section
              dangerouslySetInnerHTML={{
                __html: this.state.content,
              }}
            />
          </article>
        </section>
      </Fragment>
    );
  }
}
