import { h, Component } from 'preact';
import Icon from 'components/Icon';
import styles from './footer.module.css';

export default class Footer extends Component<any, any> {
  render(props:any) {
    return (
      <footer class={styles.footer}>
        <span>2018 &copy; J lopes</span>
        <aside>
          <Icon
            href="https://github.com/offeringofpie"
            icon="#github"
            width={16}
            height={16}
            title="Github"
            rel="noopener noreferrer"
          />
          <Icon
            href="https://www.linkedin.com/in/jpalopes/"
            icon="#linkedin"
            width={16}
            height={16}
            title="Linkedin"
            rel="noopener noreferrer"
          />
          <Icon
            href="https://twitter.com/jelo"
            icon="#twitter"
            width={16}
            height={16}
            title="Twitter"
            rel="noopener noreferrer"
          />
          <Icon
            href="https://www.instagram.com/offeringofpie/"
            icon="#instagram"
            width={16}
            height={16}
            title="Instagram"
            rel="noopener noreferrer"
          />
          <Icon
            href="https://unsplash.com/@offeringofpie"
            icon="#unsplash"
            width={16}
            height={16}
            title="Unsplash"
            rel="noopener noreferrer"
          />
          <Icon
            href="https://www.pinterest.co.uk/offeringofpie/"
            icon="#pinterest"
            width={16}
            height={16}
            title="Pinterest"
            rel="noopener noreferrer"
          />
        </aside>
        <div class={styles.spacer}></div>
      </footer>
    );
  }
}
