import { h, Component } from 'preact';
import { Route, Router } from 'preact-router';

import Nav from 'components/Nav';
import Sprite from 'components/Sprite';
import Footer from 'components/Footer';
import Post from 'components/Post';

// Code-splitting is automated for `routes` directory
import Home from 'routes/home';
import About from 'routes/about';
import Projects from 'routes/projects';
import Search from 'routes/search';
import Writings from 'routes/writings';
import Photos from 'routes/photos';
import Blog from 'routes/blog';

import colors from 'style/colors.module.css';

const posts = require('data/posts.json');
const writings = require('data/writings.json');

class App extends Component<any, any> {
  constructor() {
    super();
  }

  handleRoute = async (e: any) => {
    let color = 'c_blue';

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    switch (e.url) {
      case '/photos':
        color = 'c_gold';
        break;
      case '/writings':
        color = 'c_green';
        break;
      case '/about':
        color = 'c_pink';
        break;
      case '/search':
        color = 'c_pink';
        break;
      case '/projects':
        color = 'c_light';
        break;
    }

    if (typeof document !== 'undefined') {
      document.body.setAttribute('class', colors[color]);
    }
  };

  render() {
    return (
      <div id="app">
        <Nav />
        <Router onChange={this.handleRoute}>
          <Route path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/projects" component={Projects} />
          <Route path="/writings" component={Writings} />
          <Route path="/photos" component={Photos} />
          <Route path="/blog" component={Blog} />
          <Route path="/search" component={Search} default />

          {posts.map((v: any) => {
            return <Post data={v} path={`/blog/${v.slug}`} />;
          })}
          {writings.map((v: any) => {
            return <Post data={v} path={`/writings/${v.slug}`} />;
          })}
        </Router>
        <Footer />
        <Sprite />
      </div>
    );
  }
}
export default App;
