import { h, Component, Fragment } from 'preact';
import Icon from 'components/Icon';

import './nav.module.css';
import styles from './nav.module.css';
import colors from 'style/colors.module.css';

export default class Nav extends Component<any, any> {
  constructor() {
    super();

    this.state = {
      open: false,
      color: 'c_blue',
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    let color = 'c_blue';

    switch (location.pathname) {
      case '/photos':
        color = 'c_gold';
        break;
      case '/writings':
        color = 'c_green';
        break;
      case '/about':
        color = 'c_pink';
        break;
      case '/projects':
        color = 'c_light';
        break;
    }

    this.setState({
      color: color,
    });
  }

  onClick(e: any) {
    if (
      e.target.nodeName == 'rect' ||
      e.target.nodeName == 'svg' ||
      e.target.nodeName == 'A'
    ) {
      this.setState((prev) => {
        return { open: !prev.open };
      });
    }
  }

  render() {
    return (
      <Fragment>
        <div class={`${styles.logo}`}>
          <Icon
            href={`/`}
            icon={'#jl'}
            width={48}
            height={48}
            title="Home"
            target="_self"
            disableTooltip
          />
        </div>
        <aside
          class={`${styles['container']} ${
            this.state.open ? styles['open'] : ''
          }`}
          onClick={this.onClick}
        >
          <button
            class={styles.ham}
            name="Menu Button"
            aria-label="Menu Button"
            aria-haspopup="true"
            aria-controls="menu"
            aria-pressed={this.state.open}
          >
            <svg viewBox="0 0 100 80" width="40" height="40">
              <rect width="100" height="10" rx="8"></rect>
              <rect y="30" width="100" height="10" rx="8"></rect>
              <rect y="60" width="100" height="10" rx="8"></rect>
            </svg>
          </button>
          <nav id="menu" class={styles.nav} role="menu">
            <a href="/" className={colors['c_blue']} role="menuitem">
              Home
            </a>
            <a href="/about" className={colors['c_pink']} role="menuitem">
              About
            </a>
            <a href="/projects" className={colors['c_light']} role="menuitem">
              Projects
            </a>
            <a href="/writings" className={colors['c_green']} role="menuitem">
              Writings
            </a>
            <a href="/photos" className={colors['c_gold']} role="menuitem">
              Photos
            </a>
          </nav>
        </aside>
      </Fragment>
    );
  }
}
